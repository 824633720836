import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import ResButtonEditing from "./responseButtonEditing";
import ResButtonUI from "./responseButtonUi";

export default class ResButton extends Plugin{

    static get requires() {
        return [ResButtonEditing, ResButtonUI];
    }
}