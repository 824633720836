import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import ResButtonCommand from "./responseCommon";

export default class ResButtonEditing extends Plugin {

    static get pluginName() {
        return 'insertEmoji';
    }

    init() {
        console.log('NotifyButtonEditing#init() got called');

       /* this._defineSchema();
        this._defineConverters();
*/
        this.editor.commands.add('insertEmoji', new ResButtonCommand( this.editor ) );

        /*this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'ask-ckeditor-button' ) )
        );*/
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('notifyButton', {
            allowWhere: '$text',
            isInline: true,
            isCollapsed:true,
            isObject: true,
            withText: true,
            allowAttributes: ['name', 'event', 'value','scope','assignments']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast').elementToElement({
            view: {
                name: 'button',
                classes: ['ask-ckeditor-button']
            },
            model: (viewElement, {writer: modelWriter}) => {

                console.debug('viewElement',viewElement.toJSON());

                const e = viewElement.getAttribute('__event');
                const v = viewElement.getAttribute('__value');
                const scope = viewElement.getAttribute('__item_scope');
                const assignments = viewElement.getAttribute('th:__assignments');
                const name = viewElement.getChild( 0 ).data;

                return modelWriter.createElement('notifyButton',{name:name,event:e,value:v,scope:scope,assignments:assignments});
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'notifyButton',
            view: (modelItem, {writer: viewWriter}) => {

                console.debug('editingDowncast elementToElement modelItem',modelItem);
                const widgetElement = createNotifyButtonView(modelItem, viewWriter);

                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'notifyButton',
            view: (modelItem, {writer: viewWriter}) => createNotifyButtonView(modelItem, viewWriter)
        });

        // Helper method for both downcast converters.
        function createNotifyButtonView(modelItem, viewWriter) {

            console.debug('createNotifyButtonView modelItem',modelItem);

            const name = modelItem.getAttribute('name');
            const event = modelItem.getAttribute('event');
            const value = modelItem.getAttribute('value');
            const scope = modelItem.getAttribute('scope');
            const assignments = modelItem.getAttribute('assignments');

            let options = {
            class: 'ask-ckeditor-button',
                __event: event,
                __value:value,
                __item_scope:scope,
                "th:__assignments":assignments,
                _name: name,
                "th:onclick": "__rich_text_pro_button_onclick(this)"
            };

            const placeholderView = viewWriter.createContainerElement('button', options);

            // Insert the placeholder name (as a text).
            const innerText = viewWriter.createText(name );
            viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), innerText);

            return placeholderView;
        }
    }
}