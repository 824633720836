<template>
    <div id="attachment-upload">
        <div v-if="attachmentList.length != 0" class="image-list">
            <template>
                <div v-for="(item, index) in attachmentList" class="attch-item" :key="item.url + index">
                    <span @click.stop="deleteAttch(index)" v-if="!isDetail"><i class="iconfont guoran-a-16-09"></i></span>
                    <span @click.stop="downLoad(item, item.name)" v-if="isDetail"><i
                            class="iconfont guoran-tongyichicun-xiazai"></i></span>
                    <div class="attch-item-left">
                        <i v-if="item.url == ''" class="el-icon-loading" style="color:#366AFF;margin: 5px;"></i>
                        <img src="./../../../../assets/images/video.png" v-else-if="setSrc(item.name) == 'video'" />
                        <img src="./../../../../assets/images/image.png" v-else-if="setSrc(item.name) == 'image'" />
                        <img src="./../../../../assets/images/txt.png" v-else-if="setSrc(item.name) == 'txt'" />
                        <img src="./../../../../assets/images/word.png" v-else-if="setSrc(item.name) == 'word'" />
                        <img src="./../../../../assets/images/pdf.png" v-else-if="setSrc(item.name) == 'pdf'" />
                        <img src="./../../../../assets/images/ppt.png" v-else-if="setSrc(item.name) == 'ppt'" />
                        <img src="./../../../../assets/images/excel.png" v-else-if="setSrc(item.name) == 'excel'" />
                        <i v-else class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"
                            style="color:#366AFF;margin: 5px;"></i>
                        <div class="fileName" @click="openDialog(item, item.name)" v-if="item">
                            <template v-if="typeof item === 'string'">
                                <template v-if="item.indexOf('[') !== -1">
                                    {{ JSON.parse(item)[index] }}
                                </template>
                            </template>
                            <template v-else>
                                {{ item.name }}
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="800px" :append-to-body="true" :close-on-click-modal="false"
            custom-class="look-dialog">
            <template v-if="fieldType === 'VIDEO'">
                <video :src="dialogUrl" controls></video>
            </template>
            <template v-else-if="fieldType === 'IMAGE'">
                <img :src="dialogUrl" />
            </template>
            <div v-else-if="fieldType === 'txt' || fieldType === 'doc'" style="height: 60vh">
                <embed :src="dialogUrl" width="100%" height="100%" />
            </div>
            <div v-else style="height: 60vh">
                <iframe :src="dialogUrl" width="100%" height="100%"></iframe>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "customUpload",
    components: {},
    data() {
        return {
            dialogUrl: "",
            dialogVisible: false,
            fieldType: "",
        }
    },
    props: ["attachmentList", 'isDetail'],
    methods: {
        //查看视频
        openDialog(item, fileName) {
            let url = typeof item == 'object' ? item.url : item
            this.fieldType = ""
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            this.dialogUrl = url;
            if (fileType === '.mp4' || fileType === '.MP4') {
                this.fieldType = 'VIDEO'
                this.dialogVisible = true;
            }
            else if (fileType === '.jpg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG' || fileType === '.jpeg') {
                // this.fieldType = 'IMAGE'
                // this.dialogVisible = true;
                let imgList = []
                this.attachmentList.forEach(element => {
                    let name = element.name
                    const type = name.substring(name.lastIndexOf('.'));
                    if (type === '.jpg' || type === '.png' || type === '.JPG' || type === '.PNG') {
                        imgList.push(typeof element == 'object' ? element.url : element);
                    }
                });
                this.$emit('previewImg', imgList, url)
            }
            // else if(fileType === '.exe' || fileType === '.dwg') {
            //     this.$message.warning('当前文件不支持预览')
            // }
            else {
                // let base = btoa(encodeURIComponent(url))
                // this.dialogUrl = 'https://test.open.askbot.cn/kkfileview/onlinePreview?url=' + base
                // this.dialogVisible = true;
                let previewUrl = this.previewUrl(url)
                window.open(previewUrl, 'target');
            }
        },
        previewUrl(oldUrl) {
            let url = process.env.VUE_APP_ENV === 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url='
            if (url != '') {
                // if(/[\u4E00-\u9FA5]+/g.test(oldUrl)) {
                //     url += btoa(encodeURIComponent(oldUrl))
                // } else {
                //     url += btoa(oldUrl)
                // }
                // url += '&encodeURIComponent=true'
                if (decodeURIComponent(oldUrl) == oldUrl) {
                    url += btoa(encodeURIComponent(oldUrl))
                    url += '&encodeURIComponent=true'
                } else {
                    url += btoa(encodeURIComponent(decodeURIComponent(oldUrl)))
                    url += '&encodeURIComponent=true'
                }
            }
            return url
        },
        deleteAttch(index) {
            this.$emit('attachDeleteAttch', index)
        },
        //根据上传类型设置图标
        setSrc(fileName) {
            let file = ""
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            if (fileType === '.mp4' || fileType === '.MP4') {
                file = 'video'
            }
            else if (fileType === '.jpg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG') {
                file = 'image'
            }
            else if (fileType == '.txt') {
                file = 'txt'
            }
            else if (fileType == '.ppt' || fileType == '.pptx') {
                file = 'ppt'
            }
            else if (fileType == '.doc' || fileType == '.docx') {
                file = 'word'
            }
            else if (fileType == '.xls' || fileType == '.xlsx') {
                file = 'excel'
            }
            else if (fileType == '.pdf') {
                file = 'pdf'
            }
            return file
        },
        //下载文件
        updownload() {
            window.location.href = this.dialogUrl
            /*window.open(this.dialogUrl,'_black')*/
        },
        downLoad(item, name) {
            // const fileType = name.substring(name.lastIndexOf('.'));
            // || fileType == '.mp3'
            // if(fileType == '.txt') {
            //     this.$http.get(item.url).then(res =>{
            //         let elink = document.createElement("a");// 设置下载文件名
            //         elink.download = name ? name : decodeURIComponent(item.url.substring(item.url.lastIndexOf('/') + 1));
            //         elink.style.display = "none";
            //         let blob = new Blob([res.data]);
            //         elink.href = URL.createObjectURL(blob);
            //         document.body.appendChild(elink);
            //         elink.click();
            //         document.body.removeChild(elink);
            //         URL.revokeObjectURL(blob); //释放对象
            //     })
            // }  else {
            let url = typeof item == 'object' ? item.url : item
            if (decodeURIComponent(url) != url) {
                url = decodeURIComponent(url)
            }
            this.$http.post('/knowledge-api/temporary-certificate/or-origin?expired=10', url, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.data) {
                    let a = document.createElement('a')
                    a.href = res.data
                    a.download = name
                    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
                }
            })
            // }
        }
    }
};
</script>

<style scoped lang="less">
.custom-upload-o {
    display: flex;
    align-items: center;
}

#attachment-upload {
    .image-list {
        display: flex;
        flex-wrap: wrap;
    }

    .image-item {
        margin: 0 6px;
        position: relative;
        height: 25px;

        .delete {
            // width: 20px;
            // height: 22px;
            position: absolute;
            top: 0px;
            right: 0px;
            // border-radius: 30px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .guoran-tongyichicun-16-09-shanchu2 {
            color: #366aff;
            // position: absolute;
            // top: 0px;
            // right: 0px;
            // cursor: pointer;
        }
    }

    .guoran-tongyichicun-16-09-shanchu2 {
        display: none;
    }

    .image-item:hover {
        .delete {
            width: 18px;
            height: 20px;
        }

        .guoran-tongyichicun-16-09-shanchu2 {
            display: block;
        }
    }

    .attch-item {
        margin: 10px 0 0 0;
        color: #606266;
        font-size: 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .guoran-a-16-09 {
            color: #366aff;
        }

        .guoran-tongyichicun-xiazai {
            font-size: 13px;
            color: #366aff;
            margin-right: 5px;
        }

        .attch-item-left {
            display: flex;
            align-items: center;
            flex: none;
            width: calc(100% - 20px);

            img {
                width: 22px;
                height: 22px;
                margin-right: 4px;
            }
        }
    }

    // .guoran-a-16-09 {
    //     display: none;
    // }

    // .attch-item:hover {
    //     .guoran-a-16-09 {
    //         display: block;
    //     }
    // }

    .video-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f4fc;
        width: 60px;
        height: 60px;
        border-radius: 5px;
        margin: 0 6px;
        position: relative;
        overflow: hidden;

        img {
            cursor: pointer;
        }

        .delete {
            // width: 20px;
            // height: 22px;
            position: absolute;
            top: 0px;
            right: 0px;
            // border-radius: 30px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: flex-end;

        }

        .guoran-tongyichicun-16-09-shanchu2 {
            color: #366aff;
            cursor: pointer;
        }
    }

    .video-item:hover {
        .delete {
            width: 20px;
            height: 22px;
        }

        .guoran-tongyichicun-16-09-shanchu2 {
            display: block;
        }
    }

    /deep/.attachment-upload {
        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            width: 100%;
        }
    }

    .fileName {
        max-width: 80%;
        flex: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /deep/ .el-upload--picture-card {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }

    /deep/.el-upload-dragger {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .el-icon-upload {
            margin: 5px 10px 8px;
            line-height: 30px;
            font-size: 25px;
        }

        .el-upload__text {
            display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
            flex: 1;
            text-align: left;
        }
    }

    .el-upload-dragger>div {
        display: flex;
        align-items: center;
    }
}

.look-dialog {
    video {
        height: 500px;
        object-fit: contain;
        width: 100%;
    }

    img {
        height: 500px;
        width: 100%;
        object-fit: contain;
    }
}
</style>