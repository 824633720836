import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import FileCommand from "./common";
export default class MyFileUpload extends Plugin {
    static get pluginName() {
        return "fileUpload";
    }
    init() {
        const editor = this.editor;
        // 注册一个 BoldCommand 命令
        editor.commands.add('fileUpload', new FileCommand(editor));
    }
}