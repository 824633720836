import Command from '@ckeditor/ckeditor5-core/src/command';


export default class ResButtonCommand extends Command {

    execute(response) {
        const editor = this.editor;

        editor.model.change(writer => {
            // Create a <placeholder> elment with the "name" attribute...

            let name = response.name;
            let event = response.event;
            const InsertLink = writer.createElement('InsertLink',
                {name: name, event: event}
            );

            editor.model.insertContent(InsertLink);
            writer.setSelection(InsertLink, 'on');
        });
    }

    refresh() {
        this.isEnabled = true;
    }
}

