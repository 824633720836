const OSS = window.OSS;
const ossConfig = {
    region: "oss-cn-zhangjiakou",
    //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
    accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
    accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
    // stsToken: '<Your securityToken(STS)>',
    bucket: "guoranopen-zjk",
};

let IDX = 256, HEX = [], SIZE = 256, BUFFER;
while (IDX--) HEX[IDX] = (IDX + 256).toString(16).substring(1);

function uid(len) {
    let i = 0, tmp = (len || 11);
    if (!BUFFER || ((IDX + tmp) > SIZE * 2)) {
        for (BUFFER = '', IDX = 0; i < SIZE; i++) {
            BUFFER += HEX[Math.random() * 256 | 0];
        }
    }

    return BUFFER.substring(IDX, IDX++ + tmp);
}

function dataFormat(fmt, date = new Date()) {
    const o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (const k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function pathGenerate(filename) {
    return "front-oss/" + dataFormat("yyyy/MM/dd/hh/mm/") + uid(32) + "/" + filename.split("+").join('_');
}


function upload(ossConfig, data) {
    let ossClient = new OSS(ossConfig);
    // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
    let objectKey = pathGenerate(data.name);
    let result = ossClient.put(objectKey, data, {
        headers:{
            // 通过文件URL访问文件时，指定以附件形式下载文件，下载后的文件名称定义为example.jpg。
            'Content-Disposition': `attachment; filename="${encodeURIComponent(data.name)}"`
        },
    });
    console.debug(result);
    return result;
}
async function uploadImageByBase64(ossConfig, blob) {
    console.log(blob)
    let ossClient = new OSS(ossConfig);
    console.log(ossClient)

    // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
    let objectKey = pathGenerate(new Date().getTime());
    let result = await ossClient.put(objectKey, blob);
    console.debug(result);
    return result;
}
function multipartUpload(ossConfig, data, callback, extCallback) {
    let ossClient = new OSS(ossConfig);
    // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
    let objectKey = pathGenerate(data.name);

    let res = ossClient.multipartUpload(objectKey, data, {
        headers:{
            // 通过文件URL访问文件时，指定以附件形式下载文件，下载后的文件名称定义为example.jpg。
            'Content-Disposition': `attachment; filename="${encodeURIComponent(data.name)}"`
        },
        progress: function (p, checkpoint) {
            console.debug('progress callback', p, checkpoint);
            // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
            if (callback && callback instanceof Function) {
                callback(p, checkpoint, data, extCallback);
            }
        }
    })
    return res;
}

function ossFileUrl(ossConfig, path, cname) {
    if (cname == null) {
        return 'https://' + ossConfig.bucket + '.' + ossConfig.region + '.aliyuncs.com/' + path;
    } else {
        return 'https://' + ossConfig.bucket + '.' + ossConfig.region + '.aliyuncs.com/' + path;
        // return cname + '/' + path;
    }
}

export { upload, multipartUpload, ossFileUrl, uploadImageByBase64, ossConfig }
