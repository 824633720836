import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ToolbarUI from './toolbar_ui'
import FieldUpload from './editing'

export default class MyFileUpload extends Plugin {
    static get requires() {
        return [FieldUpload, ToolbarUI];
    }
    static get pluginName() {
        return 'MyFileUpload';
    }
}