var emojiArr = [
    {
        name: '表情',
        iconArr: [{
            className: 'bg-1f604',
            icon: '😄'
        },
        {
            className: 'bg-1f603',
            icon: '😃'
        },
        {
            className: 'bg-1f600',
            icon: '😀'
        },
        {
            className: 'bg-1f60a',
            icon: '😊'
        },
        {
            className: 'bg-1f609',
            icon: '😉'
        },
        {
            className: 'bg-1f60d',
            icon: '😍'
        },
        {
            className: 'bg-1f618',
            icon: '😘'
        },
        {
            className: 'bg-1f61a',
            icon: '😚'
        },
        {
            className: 'bg-1f617',
            icon: '😗'
        },
        {
            className: 'bg-1f619',
            icon: '😙'
        },
        {
            className: 'bg-1f61c',
            icon: '😜'
        },
        {
            className: 'bg-1f61d',
            icon: '😝'
        },
        {
            className: 'bg-1f61b',
            icon: '😛'
        },
        {
            className: 'bg-1f633',
            icon: '😳'
        },
        {
            className: 'bg-1f601',
            icon: '😁'
        },
        {
            className: 'bg-1f614',
            icon: '😔'
        },
        {
            className: 'bg-1f60c',
            icon: '😌'
        },
        {
            className: 'bg-1f612',
            icon: '😒'
        },
        {
            className: 'bg-1f61e',
            icon: '😞'
        },
        {
            className: 'bg-1f623',
            icon: '😣'
        },
        {
            className: 'bg-1f622',
            icon: '😢'
        },
        {
            className: 'bg-1f602',
            icon: '😂'
        },
        {
            className: 'bg-1f62d',
            icon: '😭'
        },
        {
            className: 'bg-1f62a',
            icon: '😪'
        },
        {
            className: 'bg-1f625',
            icon: '😥'
        },
        {
            className: 'bg-1f630',
            icon: '😰'
        },
        {
            className: 'bg-1f605',
            icon: '😅'
        },
        {
            className: 'bg-1f613',
            icon: '😓'
        },
        {
            className: 'bg-1f629',
            icon: '😩'
        },
        {
            className: 'bg-1f62b',
            icon: '😫'
        },
        {
            className: 'bg-1f628',
            icon: '😨'
        },
        {
            className: 'bg-1f631',
            icon: '😱'
        },
        {
            className: 'bg-1f620',
            icon: '😠'
        },
        {
            className: 'bg-1f621',
            icon: '😡'
        },
        {
            className: 'bg-1f624',
            icon: '😤'
        },
        {
            className: 'bg-1f616',
            icon: '😖'
        },
        {
            className: 'bg-1f606',
            icon: '😆'
        },
        {
            className: 'bg-1f60b',
            icon: '😋'
        },
        {
            className: 'bg-1f637',
            icon: '😷'
        },
        {
            className: 'bg-1f60e',
            icon: '😎'
        },
        {
            className: 'bg-1f634',
            icon: '😴'
        },
        {
            className: 'bg-1f635',
            icon: '😵'
        },
        {
            className: 'bg-1f632',
            icon: '😲'
        },
        {
            className: 'bg-1f61f',
            icon: '😟'
        },
        {
            className: 'bg-1f626',
            icon: '😦'
        },
        {
            className: 'bg-1f627',
            icon: '😧'
        },
        {
            className: 'bg-1f608',
            icon: '😈'
        },
        {
            className: 'bg-1f47f',
            icon: '👿'
        },
        {
            className: 'bg-1f62e',
            icon: '😮'
        },
        {
            className: 'bg-1f62c',
            icon: '😬'
        },
        {
            className: 'bg-1f610',
            icon: '😐'
        },
        {
            className: 'bg-1f615',
            icon: '😕'
        },
        {
            className: 'bg-1f62f',
            icon: '😯'
        },
        {
            className: 'bg-1f636',
            icon: '😶'
        },
        {
            className: 'bg-1f607',
            icon: '😇'
        },
        {
            className: 'bg-1f60f',
            icon: '😏'
        },
        {
            className: 'bg-1f611',
            icon: '😑'
        },
        {
            className: 'bg-1f63a',
            icon: '😺'
        },
        {
            className: 'bg-1f638',
            icon: '😸'
        },
        {
            className: 'bg-1f63b',
            icon: '😻'
        },
        {
            className: 'bg-1f63d',
            icon: '😽'
        },
        {
            className: 'bg-1f63c',
            icon: '😼'
        },

        {
            className: 'bg-1f640',
            icon: '🙀'
        },
        {
            className: 'bg-1f63f',
            icon: '😿'
        },
        {
            className: 'bg-1f639',
            icon: '😹'
        },
        {
            className: 'bg-1f63e',
            icon: '😾'
        },
        {
            className: 'bg-1f479',
            icon: '👹'
        },
        {
            className: 'bg-1f47a',
            icon: '👺'
        }
        ]
    },
    {
        name: '动物',
        iconArr: [{
            className: 'bg-1f436',
            icon: '🐶'
        },
        {
            className: 'bg-1f43a',
            icon: '🐺'
        },
        {
            className: 'bg-1f42d',
            icon: '🐱'
        },
        {
            className: 'bg-1f42d',
            icon: '🐭'
        },
        {
            className: 'bg-1f439',
            icon: '🐹'
        },
        {
            className: 'bg-1f430',
            icon: '🐰'
        }, {
            className: 'bg-1f438',
            icon: '🐸'
        },
        {
            className: 'bg-1f42f',
            icon: '🐨'
        },
        {
            className: 'bg-1f43b',
            icon: '🐻'
        },
        {
            className: 'bg-1f437',
            icon: '🐷'
        },
        {
            className: 'bg-1f43d',
            icon: '🐽'
        },
        {
            className: 'bg-1f42e',
            icon: '🐮'
        },
        {
            className: 'bg-1f417',
            icon: '🐗'
        },
        {
            className: 'bg-1f435',
            icon: '🐵'
        },
        {
            className: 'bg-1f412',
            icon: '🐒'
        },
        {
            className: 'bg-1f434',
            icon: '🐴'
        },
        {
            className: 'bg-1f411',
            icon: '🐑'
        },
        {
            className: 'bg-1f418',
            icon: '🐘'
        },
        {
            className: 'bg-1f43c',
            icon: '🐼'
        },
        {
            className: 'bg-1f427',
            icon: '🐧'
        },
        {
            className: 'bg-1f426',
            icon: '🐦'
        },
        {
            className: 'bg-1f424',
            icon: '🐤'
        },
        {
            className: 'bg-1f425',
            icon: '🐥'
        },
        {
            className: 'bg-1f423',
            icon: '🐣'
        },
        {
            className: 'bg-1f414',
            icon: '🐔'
        },
        {
            className: 'bg-1f40d',
            icon: '🐍'
        },
        {
            className: 'bg-1f422',
            icon: '🐢'
        },
        {
            className: 'bg-1f41b',
            icon: '🐛'
        },
        {
            className: 'bg-1f41d',
            icon: '🐝'
        },
        {
            className: 'bg-1f41c',
            icon: '🐜'
        },
        {
            className: 'bg-1f41e',
            icon: '🐞'
        },
        {
            className: 'bg-1f40c',
            icon: '🐌'
        },
        {
            className: 'bg-1f419',
            icon: '🐙'
        },
        {
            className: 'bg-1f420',
            icon: '🐠'
        },
        {
            className: 'bg-1f41f',
            icon: '🐟'
        },
        {
            className: 'bg-1f42c',
            icon: '🐬'
        },
        {
            className: 'bg-1f433',
            icon: '🐳'
        },
        {
            className: 'bg-1f40b',
            icon: '🐋'
        },
        {
            className: 'bg-1f404',
            icon: '🐄'
        },
        {
            className: 'bg-1f40f',
            icon: '🐏'
        },
        {
            className: 'bg-1f400',
            icon: '🐀'
        },
        {
            className: 'bg-1f403',
            icon: '🐃'
        },
        {
            className: 'bg-1f405',
            icon: '🐅'
        },
        {
            className: 'bg-1f407',
            icon: '🐇'
        },
        {
            className: 'bg-1f409',
            icon: '🐉'
        },
        {
            className: 'bg-1f40e',
            icon: '🐎'
        },
        {
            className: 'bg-1f410',
            icon: '🐐'
        },
        {
            className: 'bg-1f413',
            icon: '🐓'
        },
        {
            className: 'bg-1f415',
            icon: '🐕'
        },
        {
            className: 'bg-1f416',
            icon: '🐖'
        },

        {
            className: 'bg-1f401',
            icon: '🐁'
        },
        {
            className: 'bg-1f402',
            icon: '🐂'
        },
        {
            className: 'bg-1f432',
            icon: '🐲'
        },
        {
            className: 'bg-1f421',
            icon: '🐡'
        },
        {
            className: 'bg-1f40a',
            icon: '🐊'
        },
        {
            className: 'bg-1f42b',
            icon: '🐫'
        },
        {
            className: 'bg-1f42a',
            icon: '🐪'
        },

        {
            className: 'bg-1f406',
            icon: '🐆'
        },
        {
            className: 'bg-1f408',
            icon: '🐈'
        },
        {
            className: 'bg-1f429',
            icon: '🐩'
        },
        {
            className: 'bg-1f43e',
            icon: '🐾'
        },
        {
            className: 'bg-1f648',
            icon: '🙈'
        },
        {
            className: 'bg-1f649',
            icon: '🙉'
        },

        {
            className: 'bg-1f480',
            icon: '💀'
        },
        {
            className: 'bg-1f47d',
            icon: '👽'
        },
        {
            className: 'bg-1f63a',
            icon: '😺'
        },
        {
            className: 'bg-1f638',
            icon: '😸'
        },
        {
            className: 'bg-1f63b',
            icon: '😻'
        },
        {
            className: 'bg-1f63d',
            icon: '😽'
        },
        {
            className: 'bg-1f63c',
            icon: '😼'
        },
        {
            className: 'bg-1f640',
            icon: '🙀'
        },
        {
            className: 'bg-1f63f',
            icon: '😿'
        },
        {
            className: 'bg-1f639',
            icon: '😹'
        },

        {
            className: 'bg-1f63e',
            icon: '😾'
        }
        ]
    },
    {
        name: '食物',
        iconArr: [{
            className: 'bg-f2615',
            icon: '☕'
        },
        {
            className: 'bg-1f375',
            icon: '🍵'
        },
        {
            className: 'bg-1f376',
            icon: '🍶'
        },
        {
            className: 'bg-1f37c',
            icon: '🍼'
        },
        {
            className: 'bg-1f37a',
            icon: '🍺'
        },
        {
            className: 'bg-1f37b',
            icon: '🍻'
        },
        {
            className: 'bg-1f378',
            icon: '🍸'
        },
        {
            className: 'bg-1f379',
            icon: '🍹'
        },
        {
            className: 'bg-1f377',
            icon: '🍷'
        },

        {
            className: 'bg-1f374',
            icon: '🍴'
        },
        {
            className: 'bg-1f355',
            icon: '🍕'
        },
        {
            className: 'bg-1f354',
            icon: '🍔'
        },

        {
            className: 'bg-1f35f',
            icon: '🍟'
        },
        {
            className: 'bg-1f357',
            icon: '🍗'
        },

        {
            className: 'bg-1f356',
            icon: '🍖'
        },
        {
            className: 'bg-1f35d',
            icon: '🍝'
        },

        {
            className: 'bg-1f35b',
            icon: '🍛'
        },
        {
            className: 'bg-1f364',
            icon: '🍤'
        },
        {
            className: 'bg-1f363',
            icon: '🍣'
        },

        {
            className: 'bg-1f365',
            icon: '🍥'
        },
        {
            className: 'bg-1f359',
            icon: '🍙'
        },

        {
            className: 'bg-1f358',
            icon: '🍘'
        },
        {
            className: 'bg-1f35a',
            icon: '🍚'
        },
        {
            className: 'bg-1f35c',
            icon: '🍜'
        },

        {
            className: 'bg-1f372',
            icon: '🍲'
        },
        {
            className: 'bg-1f362',
            icon: '🍢'
        },
        {
            className: 'bg-1f361',
            icon: '🍡'
        },
        {
            className: 'bg-1f373',
            icon: '🍳'
        },

        {
            className: 'bg-1f35e',
            icon: '🍞'
        },
        {
            className: 'bg-1f369',
            icon: '🍩'
        },

        {
            className: 'bg-1f36e',
            icon: '🍮'
        },
        {
            className: 'bg-1f366',
            icon: '🍦'
        },
        {
            className: 'bg-1f368',
            icon: '🍨'
        },

        {
            className: 'bg-1f367',
            icon: '🍧'
        },
        {
            className: 'bg-1f382',
            icon: '🎂'
        },
        {
            className: 'bg-1f370',
            icon: '🍰'
        },

        {
            className: 'bg-1f36a',
            icon: '🍪'
        },
        {
            className: 'bg-1f36b',
            icon: '🍫'
        },
        {
            className: 'bg-1f36c',
            icon: '🍬'
        },

        {
            className: 'bg-1f36d',
            icon: '🍭'
        },
        {
            className: 'bg-1f36f',
            icon: '🍯'
        },
        {
            className: 'bg-1f34e',
            icon: '🍎'
        },
        {
            className: 'bg-1f34f',
            icon: '🍏'
        },
        {
            className: 'bg-1f34a',
            icon: '🍊'
        },
        {
            className: 'bg-1f34b',
            icon: '🍋'
        },

        {
            className: 'bg-1f352',
            icon: '🍒'
        },
        {
            className: 'bg-1f347',
            icon: '🍇'
        },

        {
            className: 'bg-1f349',
            icon: '🍉'
        },
        {
            className: 'bg-1f353',
            icon: '🍓'
        },
        {
            className: 'bg-1f351',
            icon: '🍑'
        },
        {
            className: 'bg-1f348',
            icon: '🍈'
        },

        {
            className: 'bg-1f34c',
            icon: '🍌'
        },
        {
            className: 'bg-1f350',
            icon: '🍐'
        },
        {
            className: 'bg-1f34d',
            icon: '🍍'
        },
        {
            className: 'bg-1f360',
            icon: '🍠'
        },
        {
            className: 'bg-1f346',
            icon: '🍆'
        },
        {
            className: 'bg-1f345',
            icon: '🍅'
        },

        {
            className: 'bg-1f33d',
            icon: '🌽'
        }

        ]
    },
    {
        name: '自然',
        iconArr: [{
            className: 'bg-1f490',
            icon: '💐'
        },
        {
            className: 'bg-1f338',
            icon: '🌸'
        },
        {
            className: 'bg-1f337',
            icon: '🌷'
        },
        {
            className: 'bg-1f340',
            icon: '🍀'
        },
        {
            className: 'bg-1f339',
            icon: '🌹'
        },
        {
            className: 'bg-1f33b',
            icon: '🌻'
        },
        {
            className: 'bg-1f33a',
            icon: '🌺'
        },
        {
            className: 'bg-1f341',
            icon: '🍁'
        },
        {
            className: 'bg-1f343',
            icon: '🍃'
        },
        {
            className: 'bg-1f342',
            icon: '🍂'
        },
        {
            className: 'bg-1f33f',
            icon: '🌿'
        },
        {
            className: 'bg-1f33e',
            icon: '🌾'
        },

        {
            className: 'bg-1f344',
            icon: '🍄'
        },
        {
            className: 'bg-1f335',
            icon: '🌵'
        },
        {
            className: 'bg-1f334',
            icon: '🌴'
        },

        {
            className: 'bg-1f332',
            icon: '🌲'
        },
        {
            className: 'bg-1f333',
            icon: '🌳'
        },

        {
            className: 'bg-1f330',
            icon: '🌰'
        },
        {
            className: 'bg-1f331',
            icon: '🌱'
        },
        {
            className: 'bg-1f33c',
            icon: '🌼'
        },
        {
            className: 'bg-1f310',
            icon: '🌐'
        },

        {
            className: 'bg-1f31e',
            icon: '🌞'
        },
        {
            className: 'bg-1f31d',
            icon: '🌝'
        },
        {
            className: 'bg-1f31a',
            icon: '🌚'
        },

        {
            className: 'bg-1f311',
            icon: '🌑'
        },
        {
            className: 'bg-1f312',
            icon: '🌒'
        },
        {
            className: 'bg-1f313',
            icon: '🌓'
        },

        {
            className: 'bg-1f314',
            icon: '🌔'
        },
        {
            className: 'bg-1f315',
            icon: '🌕'
        },
        {
            className: 'bg-1f316',
            icon: '🌖'
        },
        {
            className: 'bg-1f317',
            icon: '🌗'
        },
        {
            className: 'bg-1f31c',
            icon: '🌜'
        },

        {
            className: 'bg-1f31b',
            icon: '🌛'
        },
        {
            className: 'bg-1f319',
            icon: '🌙'
        },
        {
            className: 'bg-1f30d',
            icon: '🌍'
        },
        {
            className: 'bg-1f30e',
            icon: '🌎'
        },
        {
            className: 'bg-1f30f',
            icon: '🌏'
        },

        {
            className: 'bg-1f30b',
            icon: '🌋'
        },
        {
            className: 'bg-1f30c',
            icon: '🌌'
        },
        {
            className: 'bg-1f320',
            icon: '🌠'
        },
        {
            className: 'bg-f2b50',
            icon: '⭐'
        },

        {
            className: 'bg-f2600',
            icon: '☀'
        },

        {
            className: 'bg-f26c5',
            icon: '⛅'
        },
        {
            className: 'bg-f2601',
            icon: '☁'
        },
        {
            className: 'bg-f26a1',
            icon: '⚡'
        },

        {
            className: 'bg-f2614',
            icon: '☔'
        },
        {
            className: 'bg-f2744',
            icon: '❄'
        },
        {
            className: 'bg-f26c4',
            icon: '⛄'
        },

        {
            className: 'bg-1f300',
            icon: '🌀'
        },
        {
            className: 'bg-1f301',
            icon: '🌁'
        },
        {
            className: 'bg-1f308',
            icon: '🌈'
        },
        {
            className: 'bg-1f30a',
            icon: '🌊'
        },

        {
            className: 'bg-1f525',
            icon: '🔥'
        },
        {
            className: 'bg-f2728',
            icon: '✨'
        },
        {
            className: 'bg-1f31f',
            icon: '🌟'
        },
        {
            className: 'bg-1f4ab',
            icon: '💫'
        },
        {
            className: 'bg-1f4a5',
            icon: '💥'
        },
        {
            className: 'bg-1f4a2',
            icon: '💢'
        },
        {
            className: 'bg-1f4a6',
            icon: '💦'
        },
        {
            className: 'bg-1f4a7',
            icon: '💧'
        },
        {
            className: 'bg-1f318',
            icon: '🌘'
        },
        {
            className: 'bg-1f4a4',
            icon: '💤'
        },
        {
            className: 'bg-1f4a8',
            icon: '💨'
        }
        ]
    },
    {
        name: '生肖',
        iconArr: [{
            className: 'bg-1f401',
            icon: '🐁'
        },
        {
            className: 'bg-1f402',
            icon: '🐂'
        },
        {
            className: 'bg-1f405',
            icon: '🐅'
        },
        {
            className: 'bg-1f407',
            icon: '🐇'
        },
        {
            className: 'bg-1f409',
            icon: '🐉'
        },
        {
            className: 'bg-1f40d',
            icon: '🐍'
        },
        {
            className: 'bg-1f40e',
            icon: '🐎'
        },
        {
            className: 'bg-1f410',
            icon: '🐐'
        },
        {
            className: 'bg-1f412',
            icon: '🐒'
        },
        {
            className: 'bg-1f413',
            icon: '🐓'
        },
        {
            className: 'bg-1f415',
            icon: '🐕'
        },
        {
            className: 'bg-1f416',
            icon: '🐖'
        },
        {
            className: 'bg-f2648',
            icon: '♈'
        },
        {
            className: 'bg-f2649',
            icon: '♉'
        },
        {
            className: 'bg-f264a',
            icon: '♊'
        },
        {
            className: 'bg-f264b',
            icon: '♋'
        },
        {
            className: 'bg-f264c',
            icon: '♌'
        },
        {
            className: 'bg-f264d',
            icon: '♍'
        },
        {
            className: 'bg-f264e',
            icon: '♎'
        },
        {
            className: 'bg-f264f',
            icon: '♏'
        },
        {
            className: 'bg-f2650',
            icon: '♐'
        },
        {
            className: 'bg-f2651',
            icon: '♑'
        },
        {
            className: 'bg-f2652',
            icon: '♒'
        },
        {
            className: 'bg-f2653',
            icon: '♓'
        }

        ]
    },
    {
        name: '运动',
        iconArr: [{
            className: 'bg-1f4f0',
            icon: '📰'
        }, {
            className: 'bg-1f3a8',
            icon: '🎨'
        },
        {
            className: 'bg-1f3ac',
            icon: '🎬'
        }, {
            className: 'bg-1f3a4',
            icon: '🎤'
        },
        {
            className: 'bg-1f3a7',
            icon: '🎧'
        }, {
            className: 'bg-1f3bc',
            icon: '🎼'
        },
        {
            className: 'bg-1f3b5',
            icon: '🎵'
        }, {
            className: 'bg-1f3b6',
            icon: '🎶'
        },
        {
            className: 'bg-1f3b9',
            icon: '🎹'
        }, {
            className: 'bg-1f3bb',
            icon: '🎻'
        },
        {
            className: 'bg-1f3b7',
            icon: '🎷'
        }, {
            className: 'bg-1f3b8',
            icon: '🎸'
        },
        {
            className: 'bg-1f47e',
            icon: '👾'
        }, {
            className: 'bg-1f3ae',
            icon: '🎮'
        },
        {
            className: 'bg-1f0cf',
            icon: '🃏'
        }, {
            className: 'bg-1f3b4',
            icon: '🎴'
        },
        {
            className: 'bg-1f004',
            icon: '🀄'
        }, {
            className: 'bg-1f3b2',
            icon: '🎲'
        },
        {
            className: 'bg-1f3af',
            icon: '🎯'
        }, {
            className: 'bg-1f3c8',
            icon: '🏈'
        },
        {
            className: 'bg-1f3c0',
            icon: '🏀'
        }, {
            className: 'bg-f26bd',
            icon: '⚽'
        },
        {
            className: 'bg-f26be',
            icon: '⚾'
        }, {
            className: 'bg-1f3be',
            icon: '🎾'
        },
        {
            className: 'bg-1f3b1',
            icon: '🎱'
        }, {
            className: 'bg-1f3c9',
            icon: '🏉'
        },
        {
            className: 'bg-1f3b3',
            icon: '🎳'
        }, {
            className: 'bg-f26f3',
            icon: '⛳'
        },
        {
            className: 'bg-1f6b5',
            icon: '🚵'
        }, {
            className: 'bg-1f6b4',
            icon: '🚴'
        },
        {
            className: 'bg-1f3c1',
            icon: '🏁'
        }, {
            className: 'bg-1f3c7',
            icon: '🏇'
        },
        {
            className: 'bg-1f3c6',
            icon: '🏆'
        }, {
            className: 'bg-1f3bf',
            icon: '🎿'
        },
        {
            className: 'bg-1f3c2',
            icon: '🏂'
        }, {
            className: 'bg-1f3ca',
            icon: '🏊'
        },
        {
            className: 'bg-1f3c4',
            icon: '🏄'
        }, {
            className: 'bg-1f3a3',
            icon: '🎣'
        }

        ]
    },
    {
        name: '人物',
        iconArr: [{
            className: 'bg-1f442',
            icon: '👂'
        }, {
            className: 'bg-1f440',
            icon: '👀'
        }, {
            className: 'bg-1f443',
            icon: '👃'
        }, {
            className: 'bg-1f445',
            icon: '👅'
        }, {
            className: 'bg-1f444',
            icon: '👄'
        }, {
            className: 'bg-1f44d',
            icon: '👍'
        }, {
            className: 'bg-1f44e',
            icon: '👎'
        }, {
            className: 'bg-1f44c',
            icon: '👌'
        },
        {
            className: 'bg-1f44a',
            icon: '👊'
        }, {
            className: 'bg-f270a',
            icon: '✊'
        }, {
            className: 'bg-f270c',
            icon: '✌'
        }, {
            className: 'bg-1f44b',
            icon: '👋'
        }, {
            className: 'bg-f270b',
            icon: '✋'
        }, {
            className: 'bg-1f450',
            icon: '👐'
        }, {
            className: 'bg-1f446',
            icon: '👆'
        }, {
            className: 'bg-1f447',
            icon: '👇'
        },
        {
            className: 'bg-1f449',
            icon: '👉'
        }, {
            className: 'bg-1f448',
            icon: '👈'
        }, {
            className: 'bg-1f64c',
            icon: '🙌'
        }, {
            className: 'bg-1f64f',
            icon: '🙏'
        }, {
            className: 'bg-f261d',
            icon: '☝'
        }, {
            className: 'bg-1f44f',
            icon: '👏'
        }, {
            className: 'bg-1f6b6',
            icon: '🚶'
        }, {
            className: 'bg-1f4aa',
            icon: '💪'
        },
        {
            className: 'bg-1f3c3',
            icon: '🏃'
        }, {
            className: 'bg-1f483',
            icon: '💃'
        }, {
            className: 'bg-1f46b',
            icon: '👫'
        }, {
            className: 'bg-1f46a',
            icon: '👪'
        }, {
            className: 'bg-1f46c',
            icon: '👬'
        }, {
            className: 'bg-1f46d',
            icon: '👭'
        }, {
            className: 'bg-1f48f',
            icon: '💏'
        }, {
            className: 'bg-1f491',
            icon: '💑'
        },
        {
            className: 'bg-1f46f',
            icon: '👯'
        }, {
            className: 'bg-1f645',
            icon: '🙆'
        }, {
            className: 'bg-1f646',
            icon: '🙅'
        }, {
            className: 'bg-1f481',
            icon: '💁'
        }, {
            className: 'bg-1f64b',
            icon: '🙋'
        }, {
            className: 'bg-1f487',
            icon: '💇'
        }, {
            className: 'bg-1f485',
            icon: '💅'
        }, {
            className: 'bg-1f470',
            icon: '👰'
        },
        {
            className: 'bg-1f64e',
            icon: '🙎'
        }, {
            className: 'bg-1f64d',
            icon: '🙍'
        }, {
            className: 'bg-1f647',
            icon: '🙇'
        }, {
            className: 'bg-1f3a9',
            icon: '🎩'
        }, {
            className: 'bg-1f451',
            icon: '👑'
        }, {
            className: 'bg-1f452',
            icon: '👒'
        }, {
            className: 'bg-1f45f',
            icon: '👟'
        }, {
            className: 'bg-1f45e',
            icon: '👞'
        },
        {
            className: 'bg-1f461',
            icon: '👡'
        }, {
            className: 'bg-1f460',
            icon: '👠'
        }, {
            className: 'bg-1f462',
            icon: '👢'
        }, {
            className: 'bg-1f455',
            icon: '👕'
        }, {
            className: 'bg-1f454',
            icon: '👔'
        }, {
            className: 'bg-1f45a',
            icon: '👚'
        }, {
            className: 'bg-1f457',
            icon: '👗'
        }, {
            className: 'bg-1f3bd',
            icon: '🎽'
        },
        {
            className: 'bg-1f456',
            icon: '👖'
        }, {
            className: 'bg-1f458',
            icon: '👘'
        }, {
            className: 'bg-1f459',
            icon: '👙'
        }, {
            className: 'bg-1f4bc',
            icon: '💼'
        }, {
            className: 'bg-1f45c',
            icon: '👜'
        }, {
            className: 'bg-1f45d',
            icon: '👝'
        }, {
            className: 'bg-1f45b',
            icon: '👛'
        }, {
            className: 'bg-1f453',
            icon: '👓'
        },
        {
            className: 'bg-1f380',
            icon: '🎀'
        }, {
            className: 'bg-1f302',
            icon: '🌂'
        }, {
            className: 'bg-1f484',
            icon: '💄'
        }, {
            className: 'bg-1f48b',
            icon: '💋'
        }, {
            className: 'bg-1f463',
            icon: '👣'
        }, {
            className: 'bg-1f48e',
            icon: '💎'
        }, {
            className: 'bg-1f48d',
            icon: '💍'
        }

        ]
    },
    {
        name: '花样',
        iconArr: [{
            className: 'bg-1f451',
            icon: '👑'
        }, {
            className: 'bg-1f525',
            icon: '🔥'
        }, {
            className: 'bg-2728',
            icon: '✨'
        }, {
            className: 'bg-1f31f',
            icon: '🌟'
        }, {
            className: 'bg-1f4ab',
            icon: '💫'
        },
        {
            className: 'bg-1f4a5',
            icon: '💥'
        }, {
            className: 'bg-1f380',
            icon: '🎀'
        }, {
            className: 'bg-1f302',
            icon: '🌂'
        }, {
            className: 'bg-1f484',
            icon: '💄'
        }, {
            className: 'bg-1f49b',
            icon: '💛'
        },
        {
            className: 'bg-1f499',
            icon: '💙'
        }, {
            className: 'bg-1f49c',
            icon: '💜'
        }, {
            className: 'bg-1f49a',
            icon: '💚'
        }, {
            className: 'bg-f2764',
            icon: '❤'
        }, {
            className: 'bg-1f494',
            icon: '💔'
        },
        {
            className: 'bg-1f497',
            icon: '💗'
        }, {
            className: 'bg-1f493',
            icon: '💓'
        }, {
            className: 'bg-1f495',
            icon: '💕'
        }, {
            className: 'bg-1f496',
            icon: '💖'
        }, {
            className: 'bg-1f49e',
            icon: '💞'
        },
        {
            className: 'bg-1f498',
            icon: '💘'
        }, {
            className: 'bg-1f48c',
            icon: '💌'
        }, {
            className: 'bg-1f48b',
            icon: '💋'
        }, {
            className: 'bg-1f38d',
            icon: '🎍'
        }, {
            className: 'bg-1f49d',
            icon: '💝'
        },
        {
            className: 'bg-1f38e',
            icon: '🎎'
        }, {
            className: 'bg-1f392',
            icon: '🎒'
        }, {
            className: 'bg-1f393',
            icon: '🎓'
        }, {
            className: 'bg-1f38f',
            icon: '🎏'
        }, {
            className: 'bg-1f386',
            icon: '🎆'
        },
        {
            className: 'bg-1f387',
            icon: '🎇'
        }, {
            className: 'bg-1f390',
            icon: '🎐'
        }, {
            className: 'bg-1f391',
            icon: '🎑'
        }, {
            className: 'bg-1f383',
            icon: '🎃'
        }, {
            className: 'bg-1f47b',
            icon: '👻'
        },
        {
            className: 'bg-1f385',
            icon: '🎅'
        }, {
            className: 'bg-1f384',
            icon: '🎄'
        }, {
            className: 'bg-1f381',
            icon: '🎁'
        }, {
            className: 'bg-1f38b',
            icon: '🎋'
        }, {
            className: 'bg-1f389',
            icon: '🎉'
        },
        {
            className: 'bg-1f38a',
            icon: '🎊'
        }, {
            className: 'bg-1f388',
            icon: '🎈'
        }, {
            className: 'bg-1f38c',
            icon: '🎌'
        }
        ]
    }
]
export {
    emojiArr
}