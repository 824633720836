/**
 * 给定 (节点/位置) 查找最近一层循环节点
 * @return {Element} loop element
 */
function findLoopParent(element) {

    let parent = element.parent;
    if (parent == null || parent.name === "notifyList") {
        return parent;
    }
    return findLoopParent(parent);
}

function findListener(askPluginListeners, type) {
    for (let i = 0, len = askPluginListeners.length; i < len; i++) {
        if (askPluginListeners[i].event === type) {
            return askPluginListeners[i];
        }
    }
}

export {
    findLoopParent,findListener
}