import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { findListener } from "../plugin-notify-common/util";


export default class ResButtonUI extends Plugin {

    init() {
        const editor = this.editor;
        const command = editor.commands.get('insertLinkButton');
        editor.ui.componentFactory.add('insertLinkButton', (locale) => {
            const view = new ButtonView(locale);
            view.set({
                label: '链接',
                withText: false,
                tooltip: true,
                icon: '<svg t="1697010433934" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="36527" width="128" height="128"><path d="M607.934 417.857c-6.18-6.178-12.766-11.747-19.554-16.91l-0.012 0.011c-6.986-6.72-16.472-10.857-26.93-10.857-21.465 0-38.865 17.4-38.865 38.864a38.697 38.697 0 0 0 9.072 24.947h-0.001a39.02 39.02 0 0 0 9.59 8.256c3.665 3.022 7.262 5.998 10.625 9.361l3.204 3.205c40.28 40.23 28.255 109.54-12.025 149.82l-171.88 171.83c-40.279 40.23-105.762 40.23-146.042 0l-3.23-3.232c-40.281-40.278-40.281-105.81 0-145.99l75.936-75.91c9.742-7.734 15.997-19.67 15.997-33.073 0-23.313-18.899-42.211-42.212-42.211a42.01 42.01 0 0 0-23.725 7.297c-0.021-0.045-0.044-0.088-0.066-0.134l-0.81 0.757a42.455 42.455 0 0 0-8.026 7.51l-78.913 73.842c-74.178 74.23-74.178 195.633 0 269.759l3.204 3.203c74.179 74.127 195.53 74.127 269.708 0l171.83-171.88c74.075-74.175 80.356-191.185 6.281-265.312l-3.156-3.153z" p-id="36528"></path><path d="M855.62 165.804l-3.204-3.204c-74.178-74.178-195.529-74.178-269.707 0l-171.83 171.88c-74.178 74.178-78.263 181.296-4.085 255.523l3.153 3.104c3.369 3.368 6.866 6.543 10.435 9.589a36.872 36.872 0 0 0 8.993 7.31l0.077 0.062 0.012-0.01a36.508 36.508 0 0 0 18.258 4.87c20.263 0 36.69-16.428 36.69-36.69 0-5.719-1.31-11.132-3.646-15.958-4.85-10.89-13.93-17.52-20.21-23.802l-3.154-3.103c-40.278-40.278-24.983-98.796 15.295-139.074l171.931-171.83c40.18-40.281 105.685-40.281 145.966 0l3.206 3.152c40.279 40.282 40.279 105.839 0 146.068l-75.687 75.738c-10.297 7.629-16.974 19.865-16.974 33.663 0 23.123 18.746 41.87 41.87 41.87a41.668 41.668 0 0 0 21.946-6.211c0.048 0.082 0.093 0.157 0.14 0.24l1.175-1.083a42.09 42.09 0 0 0 9.529-8.793l79.766-73.603c74.233-74.177 74.233-195.53 0.055-269.708z" p-id="36529"></path></svg>'
            });
            // console.log(Template);
            //  将按钮的状态关联到命令对应值上
            view.bind("isOn", "isEnabled").to(command, "value", "isEnabled");
            // // 点击按钮时触发相应命令
            this.listenTo(view, "execute", () => {
                const listeners = editor.config.get('askPluginListener');
                console.debug('ed askPluginListener', listeners);
                let beforeButtonInsert = findListener(listeners, "INSERTLBUTTON");
                beforeButtonInsert.process({ "type": "response"}, response => {
                    editor.execute('insertLinkButton', response)
                });
            });
            return view;

        })
    }

}
