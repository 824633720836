import Command from "@ckeditor/ckeditor5-core/src/command";
import { multipartUpload, ossFileUrl } from "./AliyunIssUtil";
import { v4 as uuidv4 } from "uuid";
const ossConfig = {
    region: "oss-cn-zhangjiakou",
    //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
    accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
    accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
    // stsToken: '<Your securityToken(STS)>',
    bucket: "guoranopen-zjk",
}

function findListener(askPluginListeners, type) {
    for (let i = 0, len = askPluginListeners.length; i < len; i++) {
        if (askPluginListeners[i].event === type) {
            return askPluginListeners[i];
        }
    }
}

export default class FileCommand extends Command {
    refresh() {
        this.isEnabled = true;
    }
    execute(file) {
        if (file) {
            this.forEachFiles(file.file)
            return
        }
        var inputObj = document.createElement('input')
        inputObj.setAttribute('id', '_ef');
        inputObj.setAttribute('type', 'file');
        inputObj.setAttribute('accept', '*')
        inputObj.setAttribute("style", 'visibility:hidden');
        document.body.appendChild(inputObj);
        inputObj.click();
        inputObj.onchange = () => {
            // 循环上传文件
            let files = inputObj.files;
            for (let index = 0; index < files.length; index++) {
                const filed = files[index];
                let uid = uuidv4()
                let command = this.editor.commands.get("insertAskComponent");
                const listeners = this.editor.config.get('askPluginListener');
                let beforeButtonInsert = findListener(listeners, "UPLOAD");
                if (false && filed.type.includes('image')) {
                    if (this.editor.uploadImg) {
                        this.editor.uploadImg.push(uid)
                    } else {
                        this.editor.uploadImg = []
                        this.editor.uploadImg.push(uid)
                    }
                    this.ImgUploadCallback({ command, filed, uid, index, beforeButtonInsert })
                } else {
                    if(filed.size / 1024 / 1024 < 500) {
                        this.FileUploadCallback({ command, filed, uid, beforeButtonInsert })
                    } else {
                        beforeButtonInsert.process({
                            type: "UPLOAD",
                            param:{
                                type: 'error',
                            }
                        });
                    }
                }
            }
        }
    }
    ImgUploadCallback({ command, filed, uid, index, beforeButtonInsert }) {
        command.execute({
            tag: "span-editable",
            options: {
                name: filed.name + '正在上传...',
                data: uid,
                editable: false,
                type: 'upload'
            },
        })
        this.upload(filed).then(res => {
            this.delChildrenNode('image')
            command.execute({
                tag: "img",
                options: {
                    width: '100%',
                    alt: filed.name + index,
                    src: res.default
                },
            });
        })
    }
    FileUploadCallback({ command, filed, uid, beforeButtonInsert }) {
        // command.execute({
        //     tag: "span-editable",
        //     options: {
        //         name: filed.name + '正在上传...',
        //         data: uid,
        //         editable:false,
        //         type:'FileUpload'
        //     },
        // })
        beforeButtonInsert.process({
            type: "UPLOAD",
            param: {
                uid: uid,
                type: 'pending',
                name: filed.name
            }
        });
        this.upload(filed).then(res => {
            // let data = JSON.stringify({
            //     id: uid,
            //     default:res.default
            // })
            // command.execute({
            //     tag: "span-editable",
            //     options: {
            //         name: filed.name,
            //         data: data ,
            //         editable:false,
            //         type:'MyFile'
            //     },
            // })
            beforeButtonInsert.process({
                type: "UPLOAD",
                param: {
                    uid: uid,
                    name: filed.name,
                    type: 'success',
                    url: res.default
                }
            })
            // this.delChildrenNode('file')
        })
    }
    delChildrenNode(type) {
        let root = this.editor.model.document.getRoot()
        let children = root.getChildren()
        for (let child of children) {
            console.log(child._children._nodes, 'child._children._nodes');
            for (let index = 0; index < child._children._nodes.length; index++) {
                if (child._children._nodes[index].name == 'askComponentPlaceholderContainer') {
                    if (child._children._nodes[index]._children._nodes[0]) {
                        let attrs = child._children._nodes[index]._children._nodes[0]._attrs
                        if (attrs) {
                            let attrsList = [...attrs.keys()]
                            for (let j = 0; j < attrsList.length; j++) {
                                if (attrsList[j] == 'data') {
                                    if (type == 'file') {
                                        this.editor.uploadImg = this.editor.uploadImg.filter(id => { return id != attrs.get(attrsList[j]).id })
                                    } else {
                                        this.editor.uploadImg = this.editor.uploadImg.filter(id => { return id != attrs.get(attrsList[j]) })
                                    }
                                }
                            }
                        }
                        this.editor.model.change(writer => {
                            writer.remove(child._children._nodes[index]._children._nodes[0]);
                        });
                    }
                }
            }
        }
    }
    forEachFiles(files) {
        for (let index = 0; index < files.length; index++) {
            const filed = files[index];
            this.upload(filed).then(res => {
                // 需要回调通知父组件 上传成功
                let command = this.editor.commands.get("insertAskComponent");
                command.execute({
                    tag: "span-editable",
                    options: {
                        name: filed.name,
                        data: res.default,
                        editable: false,
                        type: 'MyFile'
                    },
                    // src: res.default
                })
            })
        }
    }

    upload(file) {
        return new Promise((resolve) => {
            /* this._initRequest();
             this._initListeners( resolve, reject, file );
             this._sendRequest( file );*/
            this.uploadFile(file, resolve)
        })
    }
    uploadFile(file, resolve) {
        let imgInfo = {
            url: '',
        }
        let res = multipartUpload(
            ossConfig,
            file,
            null,
            imgInfo
        );
        res.then(resp => {
            imgInfo.url = ossFileUrl(ossConfig, resp.name)
            resolve({
                name: resp.name,
                default: imgInfo.url
            })
        })
    }
}